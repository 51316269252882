<template>
  <div>
    <standard-page title="User Roles" :definition="$DEFINITIONS.admin.roles">
      <template v-slot:breadcrumb>
        <span>
          <router-link :to="{ name: 'admin-home'}">Admin</router-link>
        </span>
        <span>User Roles</span>
      </template>

      <template v-slot:buttons>

      </template>
      <template v-slot:content>
        Service: <select aria-controls="service" aria-hidden="true" class="custom-form-control" tabindex="-1" v-model="serviceid">
          <option v-for="service in services" :value="service.service">{{service.name}}</option>
        </select>
        <br> <br>
        <data-table :api="api" :columns="columns" @ready="onReady" list-key="roles">
        </data-table>
      </template>
    </standard-page>

  </div>
</template>

<script>
import EventBus from "@/event-bus";
export default {
  name: "Roles",
  props: ["orgid", "userid"],
  computed: {
    api() {
      return `${this.$accounts.defaults.baseURL}/accounts/roles?service_id=${this.serviceid}`;
    },
  },
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          type: 'toggle', data: 'role',
          render: data => {
            if (this.roles.findIndex(role => role.role == data) != -1) {
              return `<div class="disable az-toggle on"><span></span></div>`;
            } else {
              return `<div class="enable az-toggle"><span></span></div>`;
            }
          }
        }
      ],
      serviceid: 1,
      table: null,
      roles: [],
      services: null
    };
  },
  watch: {
    serviceid: async function (nv, ov) {
      this.getUserRoles();
    }

  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        let result = await this.$accounts.get(`accounts/services`);
        this.services = result.data.services;
        this.getUserRoles();
      } catch (e) {
        this.$toastr.e("Error getting services", "Error");
      }
    },
    async getUserRoles() {
      try {
        let result = await this.$accounts.get(`accounts/orgs/${this.orgid}/members/${this.userid}?service_id=${this.serviceid}`);
        this.roles = result.data.roles;
        if (this.table) {
          this.table.ajax.reload();
        }
      } catch (e) {
        this.$toastr.e("Error getting user roles", "Error");
      }
    },
    onReady(table) {
      this.table = table;
      let self = this;
      $(function () {
        $('#main-table tbody').on('click', '.enable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.addRole(row);
          table.ajax.reload();
        });
        $('#main-table tbody').on('click', '.disable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.removeRole(row);
          table.ajax.reload();
        });
      });
    },
    async addRole(row) {
      try {
        await this.$accounts.patch(`accounts/orgs/${this.orgid}/members/${this.userid}`, { role: row.role });
        this.roles.push({
          role: row.role
        });
        this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e("Error adding user roles", "Error");
      }
    },
    async removeRole(row) {
      try {
        await this.$accounts.delete(`accounts/orgs/${this.orgid}/members/${this.userid}`, { data: { role: row.role } });
        this.roles.splice(this.roles.findIndex(p => p.role == row.role), 1);
        this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e("Error removing user role", "Error");
      }
    },
  }
}
</script>

<style scoped>
</style>